export const onServiceWorkerUpdateReady = () => {
/*   const answer = window.confirm(
    `This application has been updated. ` +
      `Reload to display the latest version?`
  )
  if (answer === true) {
    window.location.reload()
  } */
};
export const onInitialClientRender = () => {
  /*
  if (typeof window !== undefined) {
    window.onload(()=>{
      let link = document.createElement('link');
      let script = document.createElement('script');
      script.type = 'text/javascript'
      script.src = 'https://cdn.snipcart.com/scripts/2.0/snipcart.js'
      script['data-api-key'] = "ZGExMDRlMTQtMTNhMC00OTY5LWFlZTItMDY1MmY3YTdkZGUyNjM2OTMzNDI5MTA0NTA5MTc4"
      link.href = "https://cdn.snipcart.com/themes/2.0/base/snipcart.min.css";
      link.id = "snipcart-theme";
      link.rel = "stylesheet";
      link.type = "text/css"
      document.body.appendChild(script);
      document.body.appendChild(link);
    });
  
  }
  */
}